import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {notification} from 'antd';
import {
    getCohortsListResponse,
    getDevicesListResponse,
    IAPIError,
    newDeviceOTARequest,
    searchDevicesListResponse,
} from './types';

const fetcher = async <T>(url: string, config?: AxiosRequestConfig): Promise<T | AxiosError> => {
    try {
        const {data} = await axios(url, config)
        return data
    } catch (e) {
        const {response} = e as AxiosError<IAPIError>

        notification.error({
            message: `${response?.status} ${response?.statusText}`,
            description: response?.data?.message
        })
        return e as AxiosError
    }
}

class fleetManagerAPI {
    constructor(private fetcher: <T>(url: string, params?: any) => Promise<T | AxiosError>) {
    }

    private api: string = `/fleet-manager/v1`

    private apiToken: string | null = null

    public setApiToken(token: string | null) {
        this.apiToken = token
    }

    public resetApiToken() {
        this.setApiToken(null)
    }

    private get authHeaders() {
        return {
            headers: {
                Authorization: `Bearer ${this.apiToken}`
            }
        }
    }

    private call<T>(path: string, config?: AxiosRequestConfig): Promise<T | AxiosError> {
        return this.fetcher<T>(`${this.api}/${path}`, {
            ...this.authHeaders,
            ...config
        })
    }

    public getCohortList(): Promise<getCohortsListResponse | AxiosError> {
        return this.call<getCohortsListResponse>('cohorts')
    }

    public getDevicesList(): Promise<getDevicesListResponse | AxiosError> {
        return this.call<getDevicesListResponse>('devices')
    }

    public requestDeviceOTA(request: newDeviceOTARequest): Promise<string | AxiosError> {
        return this.call<string>(`devices`, {method: 'post', data: request}).then((data) => {
            if (data instanceof AxiosError) {
                return data.response?.data?.toString() ?? "error"
            }
            return "OK"
        })
    }

}

export const fleetManager = new fleetManagerAPI(fetcher)
