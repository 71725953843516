import React, {FC, useCallback, useEffect, useState} from 'react';
import {AutoComplete, AutoCompleteProps, Button, Card, Col, Divider, Result, Row, Space, Tag, Typography} from 'antd';
import {
    fleetManager,
    getCohortsListResponse,
    getDevicesListResponse, newDeviceOTARequest,
} from '../../api'
import {ResultStatusType} from "antd/es/result";

interface OtaRequestsPageProps {
}

type Device = {
    serial: string,
    deviceType: string,
}

export const OtaRequestsPage: FC<OtaRequestsPageProps> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [cohorts, setCohorts] = useState<string[]>([]);
    const [nodes, setNodes] = useState<string[]>([]);
    const [weatherStations, setWeatherStations] = useState<string[]>([]);

    const [nodeOptions, setNodeOptions] = useState<AutoCompleteProps['options']>([]);
    const [weatherStationOptions, setWeatherStationOptions] = useState<AutoCompleteProps['options']>([]);

    const [selectedNodes, setSelectedNodes] = useState<Device[]>([]);
    const [selectedWeatherStations, setSelectedWeatherStations] = useState<Device[]>([]);

    const [selectedSerials, setSelectedSerials] = useState<string>("");
    const [result, setResult] = useState<ResultStatusType | undefined>();
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        //just combine all selected serials
        setSelectedSerials([...selectedNodes, ...selectedWeatherStations].map(it => it.serial).join(":"))
    }, [setSelectedSerials, selectedNodes, selectedWeatherStations])

    useEffect(() => {
        setLoading(true);

        fleetManager.getDevicesList().then(data => {
            setNodes((data as getDevicesListResponse).nodes)
            setWeatherStations((data as getDevicesListResponse).weatherStations)
            setLoading(false);
        })

    }, [setCohorts, setLoading]);

    const submitOTA = useCallback(() => {
        setResult(undefined)
        setLoading(true);

        const requested = [...selectedNodes, ...selectedWeatherStations] as newDeviceOTARequest;

        fleetManager.requestDeviceOTA(requested).then((res) => {
            console.log(res)
            setResult(res === "ok" ? "success" : "warning")
            setError(res.toString())
            setLoading(false)
        })
    }, [selectedNodes, selectedWeatherStations, setResult, setError])

    const onSelectNode = (data: string) => {
        if (selectedSerials.indexOf(data) === -1) {
            setSelectedNodes([...selectedNodes, {serial: data, deviceType: "node"}])
        }

    };

    const onSelectWeatherStation = (data: string) => {
        if (selectedSerials.indexOf(data) === -1) {
            setSelectedWeatherStations([...selectedWeatherStations, {serial: data, deviceType: "weatherStation"}])
        }
    };

    const getPanelValueNodes = (searchText: string) => {
        let items = nodes.filter(it => selectedSerials.indexOf(it) === -1)
        items = !searchText ? items : items.filter(it => it.indexOf(searchText) >= 0)

        return items.map(it => {
            return {label: it, value: it,}
        });
    }

    const getPanelValueWeatherStations = (searchText: string) => {
        let items = weatherStations.filter(it => selectedSerials.indexOf(it) === -1)
        items = !searchText ? items : items.filter(it => it.indexOf(searchText) >= 0)

        return items.map(it => {
            return {label: it, value: it,}
        });
    }

    const unselect = (serial: string) => {
        setSelectedNodes(selectedNodes.filter(it => it.serial !== serial));
        setSelectedWeatherStations(selectedWeatherStations.filter(it => it.serial !== serial));
    }

    const onSubmit = () => {
        submitOTA()
    }

    const onClear = () => {
        setSelectedNodes([]);
        setSelectedWeatherStations([]);
    }

    const onDismiss = () => {
        setResult(undefined)
    }

    return (
        <div className="ota-requests-page">
            <Typography.Title level={3}>OTA requests</Typography.Title>
            <br/>

            <Col>
                <Row>
                    <Card title="Node" size="small" style={{marginRight: 32}}>
                        <AutoComplete
                            options={nodeOptions}
                            style={{width: 200}}
                            onSelect={onSelectNode}
                            onSearch={(text) => setNodeOptions(getPanelValueNodes(text))}
                            placeholder="input here"
                        />
                    </Card>

                    <div>
                        <Divider orientation={"left"}>Selected Nodes</Divider>
                        {selectedNodes.filter(it => it.deviceType === "node").map(it => {
                            return (<Tag closable key={it.serial} color={"magenta"}
                                         onClose={() => unselect(it.serial)}>{it.serial}</Tag>)
                        })}
                    </div>

                </Row>
                <br/>
                <Row>
                    <Card title="Weather Station" size="small" style={{marginRight: 32}}>
                        <AutoComplete
                            options={weatherStationOptions}
                            style={{width: 200}}
                            onSelect={onSelectWeatherStation}
                            onSearch={(text) => setWeatherStationOptions(getPanelValueWeatherStations(text))}
                            placeholder="input here"
                        />
                    </Card>
                    <div>
                        <Divider orientation={"left"}>Selected Weather Stations</Divider>
                        {selectedWeatherStations.filter(it => it.deviceType !== "node").map(it => {
                            return (
                                <Tag closable key={it.serial} color={"green"} onClose={() => unselect(it.serial)}>{it.serial}</Tag>)
                        })}
                    </div>
                </Row>
                <br/>
                <Col>
                    <Button color={"primary"} onClick={onSubmit}>Request OTA</Button>
                    {result !== undefined && <Result
                        status={result}
                        title="Result of OTA request"
                        subTitle={error}
                        extra={[
                            <Button key="clear" onClick={onClear}>Clear</Button>,
                            <Button key="done" onClick={onDismiss}>Dismiss</Button>,
                        ]}
                    />}
                </Col>
            </Col>
        </div>
    );
};

