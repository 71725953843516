import React, {FC, createContext, ReactNode, useState, useEffect, useMemo} from 'react';
import {FirebaseError} from 'firebase/app';
import {useNavigate, useLocation} from 'react-router-dom'
import {Auth, signOut, signInWithCustomToken,} from 'firebase/auth';
import {Result, Button} from 'antd';
import {initFirebase, API, IAuthResponse, fleetManager} from '../../api'
import {LargeSpinner} from '../large-spinner/LargeSpinner'
import {MAIN_URLS} from '../../URLs'

interface AuthContextProps {
    children?: ReactNode
}

export const AuthContext = createContext<{
        auth: Auth | null,
        isLogged: boolean,
        logOut: () => void,
        user?: string
    }>({
        auth: null,
        isLogged: false,
        logOut: () => {},
        user: ''
    })

export const AuthProvider: FC<AuthContextProps> = ({children}) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [initialErr, setInitialErr] = useState<FirebaseError | null>(null);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const [auth, setAuth] = useState<Auth | null>(null);
    const [isLogged, setIsLogged] = useState<boolean>(false);

    const logOutHandler = () => {
        signOut(auth!);
    }

    useEffect(() => {
        initFirebase()
            .then(auth => {
                setAuth(auth as Auth);

                (auth as Auth).onAuthStateChanged(user => {
                    setInitialDataLoaded(false);
                    setIsLogged(false);
                    API.resetApiToken();
                    fleetManager.resetApiToken();
                    setInitialErr(null);

                    if (!user) {
                        setInitialDataLoaded(true);
                        navigate(`/${MAIN_URLS.LOGIN}`);
                        return;
                    };

                    user?.getIdToken()
                        .then(fbtoken => API.authenticate(fbtoken))
                        .then(authResp => signInWithCustomToken(auth as Auth, (authResp as IAuthResponse).access_token))
                        .then(userCred => userCred.user.getIdToken())
                        .then(idToken => {
                            setInitialDataLoaded(true);
                            if (idToken) {
                                API.setApiToken(idToken);
                                fleetManager.setApiToken(idToken)
                                setIsLogged(true);
                            };
                        })
                        .catch(setInitialErr);
                    });
                
            })
            .catch(setInitialErr);
    }, [navigate]);

    useEffect(() => {
        if (initialDataLoaded && isLogged && (pathname === `/${MAIN_URLS.LOGIN}`)) {
            navigate(MAIN_URLS.HOME);
        };
    }, [isLogged, initialDataLoaded, navigate, pathname]);

    const user = useMemo(() => {
        return auth?.currentUser?.email || ''
    }, [auth]);

    return (
        initialErr ? <Result status="error" title={initialErr.name} subTitle={initialErr.message}>
            {!!auth && isLogged && <div style={{textAlign: 'center'}}>
                <Button onClick={logOutHandler} type='primary'>Log out</Button>
            </div>}
        </Result> :
        initialDataLoaded ?
        <AuthContext.Provider value={{auth, isLogged, logOut: logOutHandler, user}}>{children}</AuthContext.Provider>
        : <LargeSpinner />
    );
}
