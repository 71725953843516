import {NodeInternalListItem, WeatherStationInternalListItem} from "./internal_types";
import {Datum, PlotData} from "plotly.js";

//requests
export interface IAPIError {
    error: string
    path: string
    status: number
    timestamp: number
    message: string
}

export interface IAuthResponse {
    access_token: string
    expires_in: number
    token_type: string
}

export type getReportsListResponse = {
    [deviceType in DeviceType]?: ReportData
}

export enum DeviceType {
    NODES = 'nodes',
    WEATHER_STATIONS = 'weatherStations'
}

export type ReportData = {
    [reportType in ReportType]?: {
        color: string,
        value: number
    }
}

export interface getDeviceReportResponse {
    graphData: Partial<PlotData>,
    tableData: DeviceReportTable[],
    deviceData: TransitionData,
}

export interface DeviceReportTable {
    lastChanged: string,
    latestInfo: string,
    farm: {
        name: string,
        id: string
    }
    device: {
        id: string
    },
    cultivation?: {
        id: string
    },
    country: string,
    project: string,
    farmName: string,
    batteryLevel?: number,
    inclination?: number,
    ec?: number,
    vwc?: number,
    soilType?: string,
    ecLimit?: number,
    depth?: number,
    status?: string,
    score?: number,
    vanillaStatus?: string,
    vanillaScore?: number,
    liquidResponseStatus?: string,
    liquidResponseScore?: number,
    ecTrendStatus?: string,
    ecTrendScore?: number,
    historicalEcVariationStatus?: string,
    historicalEcVariationScore?: number,
    bandwidthRangeStatus?: string,
    bandwidthRangeScore?: number,
    vwcDifference?: number,
    ecDifference?: number,
    modifierScore?: number,
    comment?: DeviceCommentSimple,
    ec15?: number,
    vwc15?: number,
    ec30?: number,
    vwc30?: number,
    smoothingApplied?: boolean, // for inverted vwc patterns report
    firmware?: string
    skipReasons?: string[],
    badReasons?: string[],
}

export interface DeviceCommentSimple {
    eventType: string,
    value: string,
    dateTime: string,
    id: string,
}

export interface NewDeviceComment {
    deviceId: string,
    deviceType: DeviceType | undefined,
    eventType: ReportType | undefined,
    value: string
}

export interface DeviceReportTableData {
    lastChanged: string,
    latestInfo: string,
    deviceId: string,
    cultivationId?: string,
    transition: string,
    country: string,
    project: string,
    farmName: string
    farmId: string,
    batteryLevel?: number,
    inclination?: number,
    ec?: number,
    vwc?: number,
    soilType?: string,
    ecLimit?: number,
    depth?: number,
    status?: string,
    score?: number,
    vanillaStatus?: string,
    vanillaScore?: number,
    liquidResponseStatus?: string,
    liquidResponseScore?: number,
    ecTrendStatus?: string,
    ecTrendScore?: number,
    historicalEcVariationStatus?: string,
    historicalEcVariationScore?: number,
    bandwidthRangeStatus?: string,
    bandwidthRangeScore?: number,
    vwcDifference?: number,
    ecDifference?: number,
    modifierScore?: number,
    comment?: DeviceCommentSimple,
    ec15?: number,
    vwc15?: number,
    ec30?: number,
    vwc30?: number,
    smoothingApplied?: boolean, // for inverted vwc patterns report
    firmware?: string,
    skipReasons?: string[],
    badReasons?: string[],
}

export interface TransitionData {
    entered: string[],
    exited: string[],
}

export enum Transition {
    Entered = "Entered",
    Exited = "Exited",
    Remained = "Remained"
}

/**
 * @deprecated use SoilContactV3Status instead
 */
export enum SoilContactStatus {
    OPTIMAL = 'OPTIMAL',
    SUBOPTIMAL = 'SUBOPTIMAL',
    BAD = 'BAD',
}

export enum SoilContactV3Status {
    GOOD = 'good',
    BAD = 'bad',
    UNKNOWN = 'UNKNOWN',
}

export enum LiquidResponseStatus {
    GOOD = 'GOOD',
    BAD = 'BAD',
    UNKNOWN = 'UNKNOWN',
}

export enum EcTrendStatus {
    INCREASING = 'INCREASING',
    DECREASING = 'DECREASING',
    STABLE = 'STABLE',
}

export enum HistoricalEcVariationStatus {
    SIGNIFICANT_HIGH = 'SIGNIFICANT_HIGH',
    SIGNIFICANT_LOW = 'SIGNIFICANT_LOW',
    NOT_SIGNIFICANT = 'NOT_SIGNIFICANT',
}

export enum BandwidthRangeStatus {
    OPTIMAL = 'OPTIMAL',
    NOT_OPTIMAL_OR_UNKNOWN = 'NOT_OPTIMAL_OR_UNKNOWN',
}

export interface getCultivationReportResponse {
    graphData: Partial<PlotData>,
    tableData: CultivationReportTable[]
}

export interface CultivationReportTable {
    lastChanged: string,
    latestInfo: string,
    farm: {
        name: string,
        id: string
    }
    cultivation: {
        id: string,
        name: string
    },
    country: string,
    project: string,
    moistureStatus: string,
    soilStatus: string,
    irrigationAdvice: string,
}

export interface CultivationReportTableData {
    lastChanged: string
    latestInfo: string
    cultivationId: string
    cultivationName: string
    country: string
    project: string
    farmName: string
    farmId: string
    moistureStatus: string
    soilStatus: string
    irrigationAdvice: string
}

export interface getInternalNodesResponse {
    nodes: NodeInternalListItem[]
}

export interface getInternalWeatherStationsResponse {
    weatherStations: WeatherStationInternalListItem[]
}

export interface getNodesResponse {
    node: Node
}

export interface getFirmwareStatisticsResponse {
    firmwareStatistics: FirmwareStatistics
}

export interface getMeasurementsResponse {
    measurements: Measurements
}

export enum Depth {
    DEPTH_15 = 'depth15',
    DEPTH_30 = 'depth30',
    DEPTH_45 = 'depth45',
    DEPTH_60 = 'depth60'
}

export interface Measurements {
    aims: {
        actual: GraphData,
        prediction: GraphData
    },
    bandwidth: {
        [h: string]: GraphData
    },
    [Depth.DEPTH_15]: DepthGraph,
    [Depth.DEPTH_30]: DepthGraph,
    [Depth.DEPTH_45]?: DepthGraph,
    [Depth.DEPTH_60]?: DepthGraph,
    ganyo?: { // ganyo is optional for now
        actual: GraphData,
        prediction: GraphData
    },
    weather: Weather,
    latest: LatestMeasurements,
    rootdepth: GraphData
}

export interface Weather {
    icons: GraphData,
    precipitation: GraphData,
    temperatureMax: GraphData,
    temperatureMin: GraphData,
    windSpeed: GraphData,
}

export type LatestMeasurements = {
    soilTemp: number,
    rootDepth: number,
    [Depth.DEPTH_15]: LatestMeasurementAtADepth,
    [Depth.DEPTH_30]: LatestMeasurementAtADepth,
    [Depth.DEPTH_45]?: LatestMeasurementAtADepth,
    [Depth.DEPTH_60]?: LatestMeasurementAtADepth,
}

export interface LatestMeasurementAtADepth {
    datetime: string,
    ec: number,
    temperature: number,
    vwc: number
}

export type GraphData = {
    x: Datum[],
    y: Datum[]
}

export type DepthGraph = {
    ec: GraphData,
    temperature: GraphData,
    vwc: GraphData
}

export interface getCultivationInfoResponse {
    active: Partial<Cultivation>
    inactive: Partial<Cultivation>
    historic: Partial<Cultivation>
}

export type Cultivation = {
    id: string,
    name: string,
    project: string,
    sowingDate: string
    user: string,
    zone: Zone,
    farm: Farm,
    crop: Crop,
    node: Node,
    moistureStatus: string | null,
    irrigationSystem: {
        maxAmount: number,
        minAmount: number,
        type: string
    },
    warnings: string[]
}

export interface getCultivationsResponse {
    cultivations: CultivationListItem[]
}

export interface getFarmsResponse {
    farms: FarmListItem[]
}

export interface getCultivationsByFarmResponse {
    active: CultivationByFarm[]
    inactive: CultivationByFarm[]
}

export interface FirmwareStatistics {
    batteryDepletionOTA: BatteryDepletionOTA[],
    firmwareStats: FirmwareStats[],
}

export interface BatteryDepletionOTA {
    prevVersion: string,
    nextVersion: string,
    batteryDepletion: number,
}

export interface FirmwareStats {
    firmwareVersion: string,
    averageBatteryDepletion: number,
    averageRegistrationDuration: number,
    averageRegistrationInterval: number,
    countDeployment: number,
    countEmptyMeasurement: number,
    countInvalidMeasurement: number,
    countMeasurement: number,
    countNetwork2G: number,
    countNetwork2G3G: number,
    countNetwork4G: number,
    countNetworkAuthentication: number,
    countNetworkDeregistration: number,
    countNetworkGSM: number,
    countNetworkProviderSwitch: number,
    countNetworkRegistration: number,
    countNetworkSwitch: number
}

export type CultivationByFarm = {
    id: string,
    name: string,
    moistureStatus: string,
    warning: boolean
}

export type CultivationListItem = {
    id: string,
    name: string,
    farm: Farm,
    userName: string,
    country: string
}

export type Gps = {
    latitude: number,
    longitude: number
}

export type FarmAddress = {
    city: string
    countryCode: string
    dateTime: Date
    gps: Gps,
    Id: string
    Region: string,
    Street: string,
    ZipCode: string
}

export type FarmListItem = {
    address?: FarmAddress,
    id: string,
    name: string,
    project?: Project,
}

export interface CultivationTableData {
    cultivationId: string,
    cultivationName: string,
    farmId: string,
    farmName: string,
    projectName: string,
    userName: string,
    country: string
}

export interface FarmTableData {
    farmName: string,
    farmId: string,
    projectName: string,
    country: string,
    createdAtTime: string
}

export interface FirmwareTableData {
    firmwareVersion: string,
    countDeployment: number,
    averageBatteryDepletion: number,
    countMeasurement: number,
    countEmptyMeasurement: number,
    countInvalidMeasurement: number,
    countNetworkRegistration: number,
    countNetworkDeregistration: number,
    countNetworkAuthentication: number,
    countNetwork2G: number,
    countNetwork2G3G: number,
    countNetwork4G: number,
    countNetworkGSM: number,
    countNetworkSwitch: number,
    countNetworkProviderSwitch: number,
    averageRegistrationDuration: number,
    averageRegistrationInterval: number
}

export interface OtaTableData {
    prevVersion: string,
    nextVersion: string,
    batteryDepletion: number
}

// report types
export enum ReportType {
    ACTIVE = 'active',
    ASSEMBLED = 'assembled',
    BATTERY_DEPLETION = 'batteryDepletion',
    BATTERY_WARNINGS = 'batteryWarnings',
    BATTERY_MEDIUM = 'batteryMedium',
    INACTIVE = 'inactive',
    POTENTIAL_WARNINGS = 'potentialWarnings',
    SIGNAL_LOST = 'signalLost',
    USER_LINKED = 'userLinked',
    PRECISE_BAD_SOIL_CONTACT = 'preciseBadSoilContact',
    SUBOPTIMAL_BAD_SOIL_CONTACT = 'suboptimalSoilContact',
    BAD_SOIL_CONTACT_V3 = 'badSoilContactV3',
    CULTIVATION_MISSING = 'cultivationMissing',
    INCLINATION_HIGH = 'inclinationHigh',
    ADVICE_MISSING = 'adviceMissing',
    REMOVED = 'removed',
    LOW_VWCS_ECS = 'lowVWCOrEC',
    INCORRECT_GPS = 'incorrectGPS',
    ZERO_GPS = 'zeroGPS',
    MULTIPLE_SENSORS_IN_ZONE = 'multipleSensorsInZone',
    DRY_CULTIVATIONS = 'tooDryCultivation',
    AIR_MEASUREMENTS = 'airMeasurements',
    LARGE_VWC_DIFFERENCE = 'largeVWCDifference',
    NEWLY_INSTALLED_SENSORS = 'newSensorInstalled',
    INVERTED_VWC_PATTERNS = 'invertedVWCPatterns',
    INVALID_SENSOR_MEASUREMENTS = 'invalidSensorMeasurements',
    EMPTY_SENSOR_MEASUREMENTS = 'emptySensorMeasurements',
    NO_SENSOR_MEASUREMENTS = 'noSensorMeasurements',
    MULTIPLE_DEPLOYMENTS = 'multipleDeployments',
    IDLE_MODE_DEPLETION = 'idleModeDepletion',
    LOW_BATTERY_LEVEL_CROP_SENSOR = 'lowBatteryLevelCropSensor',
    DATA_TRANSMISSION_GAPS = 'dataTransmissionGaps',
    MISSING_SOIL_INFORMATION = 'missingSoilInformation',
    IRRIGATION_ADVICE = 'irrigationAdvice',
}

// other
export type User = {
    email: string,
    id: string,
    memberSince: string,
    name: string,
    readAll: boolean,
    superAdmin: boolean
}

export type Farm = {
    active?: boolean,
    id: string,
    isDummyFarm?: boolean,
    name: string,
    parcels?: Parcel[],
    zones?: Zone[],
    project?: Project,
}

export type Node = {
    zoneId: string
    geoLocation: Polyline
    id: string
    farmId: string
    battery: number
}

export type Sensor = {
    calibrationTime: number | null
    depth: number
    id: string
    offset: number
    offsetSource: string
}

export type NodeOwner = {
    deviceContextId: string
    endTime: number | null
    farmId: string
    startTime: number | null
}

export type Parcel = {
    id: string,
    name: string,
    polyline: Polyline[]
}

export type Polyline = {
    altitude: number | null,
    latitude: number,
    longitude: number
}

export type Zone = {
    crops: Crop[],
    drainageData: DrainageData,
    id: string,
    irrigationSystem: string | null,
    name: string,
    parcelId: string,
    polyline: Polyline[],
    showData: boolean,
    soilData: SoilData
}

export type SoilData = {
    classificationLowerGround: string | null,
    classificationUpperGround: string | null,
    compositionLayers: CompositionLayer[]
}

export type CompositionLayer = {
    clay: number,
    maxDepth: number,
    organicMatter: number,
    sand: number,
    silt: number
}

export type Crop = {
    name: string | null
    bumpHeight: number | null,
    startTime: string,
    endTime: string,
    id: string,
    sowingDepth: number | null,
    type: number,
    zoneId: string
}

export type DrainageData = {
    averageHighGroundWaterLevel: number,
    averageLowGroundWaterLevel: number,
    currentGroundWaterLevel: number | null,
    ditchData: DrainageSubData,
    drainDistance: number | null,
    drainagePipeData: DrainageSubData,
    seepage: number,
    trenchData: DrainageSubData
}

export type DrainageSubData = {
    depth: number | null,
    drainageResistance: number | null,
    infiltrationResistance: number | null,
    present: boolean
}

export type Project = {
    name: string
}


export type getCohortsListResponse = {
    cohorts: string[]
}

export type getDevicesListResponse = {
    nodes: string[]
    weatherStations: string[]
}

export type searchDevicesListResponse = {
    nodes: string[]
    weatherStations: string[]
}

export type DeviceOTARequest = {
    serial: string
    deviceType: string
}
export type newDeviceOTARequest = DeviceOTARequest[]